.dialog {
  max-width: 600px;
  width: 600px;
}

.modalContent {
  padding: 15px;
  border: none;
}

.modalHeader {
  font-size: 22px;
  font-weight: 600;
  border: none;
}

.modalTitle {
  margin-bottom: 0;
  line-height: 1.5;
}

.modalBody {
  text-align: center;
}

.faTimes {
  font-size: 80px;
  color: red;
  margin-bottom: 20px;
  margin-top: -30px;
}

.subTitle {
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  padding: 0;
  border: none;
  margin-bottom: 20px;
  color: #2c3e50;
}
.content {
  text-align: center;
  font-size: 20px;
}

.contact {
  margin-top: 40px;
  text-align: center;
  font-size: 17px;
  margin-bottom: 20px;
  i {
    margin-right: 5px;
    color: #4099ff;
  }

  a {
    font-size: 17px;
  }
}
.confirmWrap {
  text-align: left;
  width: 670px;
  background-color: white;
  color: black;
  border-radius: 8px;
  margin-top: 30px;
  padding: 36px;
}

.cont {
  margin-top: 20px;
  line-height: 1.5rem;
}

.buttonBtn {
  text-align: center;
  margin-top: 30px;
}
.auth-box {
  text-align: left;
  padding: 20px 30px;
}

.pw-policy-popover {
  text-align: left;
  border: 1px solid #4099ff;
  border-radius: 12px;
  font-size: 13px;
  color: black;
  margin-top: -10px;
  padding: 10px;
  position: absolute;
  width: 390px;
  z-index: 999;
  background-color: white;
  > div {
    margin-top: 5px;
    > label {
      line-height: 20px;
      > input {
        display: none;
      }

      > span.graphics {
        &.valid {
          border-color: #4099FF;
          > i {
            transform: scale(1) rotateZ(0deg);
            opacity: 1;
          }
        }
        &:not(.valid) {
          border-color: red;
          > i {
            transform: scale(3) rotateZ(-20deg);
            opacity: 0;
          }
        }
        border-radius: 0;
        border: 2px solid;
        cursor: pointer;
        display: inline-block;
        float: left;
        height: 20px;
        position: relative;
        width: 20px;
        margin-right: 0.5rem;
        > i {
          transition: all 0.3s ease-in;
          color: #4099ff;
          font-size: 0.8em;
          left: 0;
          line-height: 0;
          position: absolute;
          right: 0;
          text-align: center;
          top: 50%;
        }
      }

      > span.text {
        display: block;
        margin-left: 30px;
      }
    }
  }
}

.pwErrorMsg {
  color: red;
  text-align: left;
  margin-top: -12px;
  margin-bottom: 18px;
}

.checkboxWrapper {
  padding-left: 7px;
}

.terms-link {
  text-decoration: none;color: #4099ff;
}

.sign-up-btn {
  width: 100%;
}

.sign-form-group {
  margin-bottom: 2.5em;
}

.checkboxError:global {
  +label::before {
    border-color: red!important;
  }
}
.inputError:global {
  border-color: red!important;
  color: red!important;
  +label::before {
    color: red!important;
  }
}

.idAlreadyMsg {
  color: red;
  text-align: left;
  margin-top: -12px;
  margin-bottom: 18px;
}
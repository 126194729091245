.confirmWrapper {
  width: 670px;
  background-color: white;
  border-radius: 8px;
  margin-top: 30px;
  padding: 36px;
  text-align: left;
  color: #5a5a5a;

  .cont {
    margin-top: 20px;
    line-height: 1.5rem;
  }

  .a {
    margin-right: 3px;
    margin-left: 3px;

    &:not(.disabled) {
      color: #4099FF;
      cursor: pointer;
    }
    &.disabled {
      color: #ccc;
      text-decoration: line-through;
      cursor: no-drop;
    }
  }

  .timer {
    color: #4099ff;
  }
}

.emailDesc {
  margin-left: 5px;
  text-decoration: underline;
  color: #4099FF;
}
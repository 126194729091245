input.input {
  box-sizing: border-box;
  width: 100%;
  height: 43px;
  margin: 0 0 15px;
  padding: 6px 0px;
  border: none;
  border-bottom: 1px solid rgba(0,0,0,0.15);
  background: #fff;
  font-size: 16px;
  resize: none;
  outline: none;


  &:valid {
    +label:before {
      color: #4099FF;
    }
  }

  &:invalid {
    +label:before {
      color: #5a5a5a;
    }
  }

  &:focus, &:valid {
    border-color: #4099ff;
    +label {
      &:before {
        transform: translate(0, -1.6em);
        font-size:14px;
        font-weight:600;
      }
    }
    &[required] {
      +label:after {
        transform: translate(0, -1.6em);
        font-size:14px;
        font-weight:600;
      }
    }
  }

  &[required] {
    +label:after {
      content: '*';
      display: inline-block;
      color: red;
      white-space: nowrap;
      transition: 0.3s ease-in-out;
      background-image: linear-gradient(to bottom, #fff, #fff);
      background-size: 100% 5px;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  +label {
    display: block;
    pointer-events: none;
    margin-top: -52px;
    font-size: 16px;
    &:before {
      content: attr(placeholder);
      display: inline-block;
      color: #7d7d7d;
      white-space: nowrap;
      transition: 0.3s ease-in-out;
      background-image: linear-gradient(to bottom, #ffffff, #ffffff);
      background-size: 100% 5px;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}
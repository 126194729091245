.sign-up-text {
  overflow: hidden;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size:14px;
  margin-bottom:20px;
  span {
    padding-left:7px;
    color:#4099ff;
    cursor:pointer;
  }
}

.text-inverse, .sign-up-text {
  color:#5a5a5a !important
}
.form-group {
  margin-bottom: 2.5em!important;
}

.logo-text {
  font-size:30px;
  font-weight:800
}

.pcoded-micon {
  position:relative;
  top:5px;
}

.idMsg, .pwMsg {
  color:red;
  text-align:left;
  margin-top: -12px;
  margin-bottom: 18px;
  display:none;
}

.resend-btn {
  text-decoration:underline;
  cursor:pointer;
  margin-right:3px;
}
.sign-in {
  width:100%
}
.auth-box {
  text-align:left;
  padding: 20px 30px;
}

.checkbox-wrapper {
  float:left;
  padding-left:4px;
}


.forgotPassword:hover {
  color:#4099ff !important;
}


.content {
  .content-box {
    width: auto;
    position: relative;
    .big-content {
      position: relative;
      width: 22rem;
      min-height: 22rem;
      margin: 0 auto 3rem;

      .list-square {
        float: left;
        .square {
          display: block;
          background: transparent;
          width: 5rem;
          height: 5rem;
          -webkit-border-radius: 0.5rem;
          -moz-border-radius: 0.5rem;
          -ms-border-radius: 0.5rem;
          border-radius: 0.5rem;
          border: 1rem solid #FFFFFF;
          margin-bottom: 1.5rem;
        }
      }
      .list-line {
        float: left;
        margin-left: 2rem;
        .line {
          display: block;
          background: #FFFFFF;
          width: 15rem;
          height: 1rem;
          -webkit-border-radius: 0.5rem;
          -moz-border-radius: 0.5rem;
          -ms-border-radius: 0.5rem;
          border-radius: 0.5rem;
          margin-bottom: 2.3rem;
        }
      }
      .fa-search {
        position: absolute;
        top: 10rem;
        left: 15rem;
        font-size: 10rem;
        color: #00c8aa;
        -webkit-animation: corner 5s infinite;
        -moz-animation: corner 5s infinite;
        animation: corner 5s infinite;
      }
      .clear {
        clear: both;
      }
    }
  }
  height: 100vh;
  overflow: hidden;
  z-index: 10;
  position: relative;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
}

.particles-js {
  position: fixed;
  top: 25vh;
  left: 25vw;
  width: 50vw;
  height: 50vh;
  z-index: -10;
}

p {
  font-family: "Open Sans", "Helvetica Neue", "Lucida Grande", Arial, Verdana, sans-serif;
  margin: 0;
  font-size: 1.5rem;
  line-height: 1.8;
  color: #d2d6e4;
  font-weight: 400;
  text-align: center;
}

h1 {
  color: #FFFFFF;
  font-family: Montserrat, "Helvetica Neue", "Lucida Grande", Arial, Verdana, sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 1;
  margin: 0 0 1.5rem 0;
  text-align: center;
  font-size: 3rem;
}

.logoWrapper {
  position: absolute;
  z-index: 20;
  display: block;
  top: 3rem;
  left: 3rem;
  width: 5rem;
}

body {
  background: #2980b9;
  -webkit-transition: all, 0.2s, ease-in-out;
  -moz-transition: all, 0.2s, ease-in-out;
  transition: all, 0.2s, ease-in-out;
  font-family: "Open Sans", "Helvetica Neue", "Lucida Grande", Arial, Verdana, sans-serif;
  color: #000000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-size: 1.4rem;
  line-height: 1.8;
  font-weight: 400;
  letter-spacing: 0;
  height: 100%;
}

body, html {
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
}

html {
  font-size: 62.5%;
}

@-webkit-keyframes corner {
  0% {
    -webkit-transform: translate(-2rem, 0);
    -webkit-animation-timing-function: 0, 0.02, 0, 1.01;
  }
  20% {
    -webkit-transform: translate(-15rem, 2rem);
  }
  40% {
    -webkit-transform: translate(-7rem, -7rem);
    animation-timing-function: cubic-bezier(0, 0.02, 0, 1.01);
  }
  60% {
    -webkit-transform: translate(-15rem, -10rem);
  }
  80% {
    -webkit-transform: translate(2rem, -12rem);
  }
  100% {
    -webkit-transform: translate(-2rem, 0);
  }
}
@-moz-keyframes corner {
  0% {
    -moz-transform: translate(-2rem, 0);
    -moz-animation-timing-function: 0, 0.02, 0, 1.01;
  }
  20% {
    -moz-transform: translate(-15rem, 2rem);
  }
  40% {
    -moz-transform: translate(-7rem, -7rem);
    animation-timing-function: cubic-bezier(0, 0.02, 0, 1.01);
  }
  60% {
    -moz-transform: translate(-15rem, -10rem);
  }
  80% {
    -moz-transform: translate(2rem, -12rem);
  }
  100% {
    -moz-transform: translate(-2rem, 0);
  }
}
@keyframes corner {
  0% {
    -webkit-transform: translate(-2rem, 0);
    -moz-transform: translate(-2rem, 0);
    -ms-transform: translate(-2rem, 0);
    -o-transform: translate(-2rem, 0);
    transform: translate(-2rem, 0);
    -webkit-animation-timing-function: 0, 0.02, 0, 1.01;
    -moz-animation-timing-function: 0, 0.02, 0, 1.01;
    animation-timing-function: 0, 0.02, 0, 1.01;
  }
  20% {
    -webkit-transform: translate(-15rem, 2rem);
    -moz-transform: translate(-15rem, 2rem);
    -ms-transform: translate(-15rem, 2rem);
    -o-transform: translate(-15rem, 2rem);
    transform: translate(-15rem, 2rem);
  }
  40% {
    -webkit-transform: translate(-7rem, -7rem);
    -moz-transform: translate(-7rem, -7rem);
    -ms-transform: translate(-7rem, -7rem);
    -o-transform: translate(-7rem, -7rem);
    transform: translate(-7rem, -7rem);
    animation-timing-function: cubic-bezier(0, 0.02, 0, 1.01);
  }
  60% {
    -webkit-transform: translate(-15rem, -10rem);
    -moz-transform: translate(-15rem, -10rem);
    -ms-transform: translate(-15rem, -10rem);
    -o-transform: translate(-15rem, -10rem);
    transform: translate(-15rem, -10rem);
  }
  80% {
    -webkit-transform: translate(2rem, -12rem);
    -moz-transform: translate(2rem, -12rem);
    -ms-transform: translate(2rem, -12rem);
    -o-transform: translate(2rem, -12rem);
    transform: translate(2rem, -12rem);
  }
  100% {
    -webkit-transform: translate(-2rem, 0);
    -moz-transform: translate(-2rem, 0);
    -ms-transform: translate(-2rem, 0);
    -o-transform: translate(-2rem, 0);
    transform: translate(-2rem, 0);
  }
}
.btn {
  width: 100%;
  margin-bottom: 15px;
}

.emailField {
  margin-bottom: 2.5em;
  text-align: left;
}

.idMsg {
  color: red;
  text-align: left;
  margin-top: -10px;
  margin-bottom: 10px;
}

.form {
  width: 450px;
  border-radius: 30px;
  position: relative;
  display: block;
  margin: 15px 0;
}

.resendBtn {
  margin-left: 4px;
  &:not(.disabled) {
    color: red;
    cursor: pointer;
    text-decoration: underline;
  }
  &.disabled {
    color: #ccc;
    cursor: no-drop;
    text-decoration: line-through;
  }
}

.timer {
  color: red;
}